import rootStore from '../state/root-store';
import { selectAccount } from '../state/modules/data';

export const customizationAccountMapping = {
  PROMEDIA: 71,
  GUCAPITAL: 26,
  ADMIN: 63,
  SVS: 6,
};

const getAccountIfNotPresent = (accountId?: number | string) => {
  let selectedId = accountId;
  if (!selectedId) {
    const state = rootStore.store.getState();
    ({ id: selectedId } = selectAccount(state));
  }
  return +(selectedId ?? 0);
};

export const noPct = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const noAgenda = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
    customizationAccountMapping.GUCAPITAL,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const noFatturazione = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const noPratiche = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
    customizationAccountMapping.GUCAPITAL,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const noAccountLimit = (id?: number | string, limit?: number) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0 && (limit ?? 1) > 0;

export const noRiepilogoLast = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const noOnorari = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const hasTariffaOraria = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const noControparte = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const noDipartimento = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const hasSpese = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const noCliente = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const noClienteInTimesheet = (id?: number | string) =>
  [
    customizationAccountMapping.PROMEDIA,
    customizationAccountMapping.GUCAPITAL,
    customizationAccountMapping.ADMIN,
  ].indexOf(getAccountIfNotPresent(id)) >= 0;

export const hasFatturazioneV2 = (id?: number | string) =>
  [customizationAccountMapping.SVS].indexOf(getAccountIfNotPresent(id)) >= 0;
