import { ofType } from 'redux-observable';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, iif } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_USER_LIST } from '../../data.const';
import {
  setListConfigAction,
  setLoaders,
  storeUserListAction,
} from '../../data.action';

const retrieveUserListEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_USER_LIST),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_USER_LIST}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ userLoader: true })),
        iif(
          () => body?.pageSize <= 100,
          of(setListConfigAction({ user: body })),
          EMPTY
        ),
        defer(() =>
          from(
            request({ path: `user/list`, method: httpMethods.POST, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return { total: p?.total, result: [...p?.result] };
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_USER_LIST}]`, p)),
            // switchMap(() => EMPTY),
            switchMap((data) => of(storeUserListAction(data))),
            catchError((err) => {
              console.error(`[epic ${RETRIEVE_USER_LIST}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ userLoader: false }))
      );
    })
  );

export default retrieveUserListEpic;
