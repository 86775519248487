import { ofType } from 'redux-observable';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, iif } from 'rxjs';
import { navigate } from '@reach/router';
import { httpMethods, request } from '../../../../../services/http-service';
import { UPSERT_TIMESHEET } from '../../data.const';
import {
  resetCurrentCreateSessionAction,
  retrieveTimesheetListAction,
  setCurrentTimesheetAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const upsertTimesheetEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_TIMESHEET),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, listBody, ...rest } = payload;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return { body: rest, listBody };
    }),
    tap((p) => console.log(`[epic ${UPSERT_TIMESHEET}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, listBody }, state]) => {
      return concat(
        of(setLoaders({ timesheetLoader: true, page: true })),
        defer(() =>
          from(
            request({ path: `timesheet`, method: httpMethods.POST, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            switchMap((data) =>
              concat(
                of(setCurrentTimesheetAction(data)),
                iif(
                  () => listBody,
                  of(retrieveTimesheetListAction(listBody)),
                  EMPTY
                ),
                of(null).pipe(
                  tap(() => {
                    if (body?.id) {
                      return; // do not navigate in case of edit
                    }

                    switch (body.fase_attivita) {
                      case 'Evento':
                      case 'Attività':
                        // navigate(
                        //   `${rootConfig.app_path}/gestionale/agenda-edit/${
                        //     data?.id || 'crea'
                        //   }`
                        // );
                        navigate(`${rootConfig.app_path}/gestionale/agenda`);
                        break;
                      default:
                        navigate(
                          `${rootConfig.app_path}/gestionale/timesheets`
                        );
                        break;
                    }
                  }),
                  delay(500),
                  switchMap(() =>
                    of(
                      setPopupInfo({
                        opened: true,
                        message: 'Operazione completata con successo',
                        timer: 3000,
                      }),
                      resetCurrentCreateSessionAction()
                    )
                  )
                )
              )
            ),
            catchError((err) => {
              console.error(`[epic ${UPSERT_TIMESHEET}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message,
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ timesheetLoader: false, page: false }))
      );
    })
  );

export default upsertTimesheetEpic;
