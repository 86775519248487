import { ofType } from 'redux-observable';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, iif } from 'rxjs';
import { navigate } from '@reach/router';
import { httpMethods, request } from '../../../../../services/http-service';
import { UPSERT_DOCUMENTO } from '../../data.const';
import {
  resetCurrentCreateSessionAction,
  retrieveDocumentoListAction,
  setCurrentDocumentoAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const upsertDocumentoEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_DOCUMENTO),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, listBody, ...rest } = payload;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return { body: rest, listBody };
    }),
    tap((p) => console.log(`[epic ${UPSERT_DOCUMENTO}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, listBody }, state]) => {
      return concat(
        of(setLoaders({ documentoLoader: true, page: true })),
        defer(() =>
          from(
            request({ path: `documento`, method: httpMethods.POST, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            switchMap((data) =>
              concat(
                of(setCurrentDocumentoAction(data)),
                iif(
                  () => body?.id && listBody,
                  of(retrieveDocumentoListAction(listBody)),
                  EMPTY
                ),
                of(null).pipe(
                  tap(() => {
                    if (body?.id) {
                      return; // do not navigate in case of edit
                    }
                    navigate(
                      `${rootConfig.app_path}/gestionale/agenda-edit/${
                        data?.id || 'crea'
                      }`
                    ).then(() => null);
                  }),
                  delay(500),
                  switchMap(() =>
                    of(
                      setPopupInfo({
                        opened: true,
                        message: 'Operazione completata con successo',
                        timer: 3000,
                      }),
                      resetCurrentCreateSessionAction()
                    )
                  )
                )
              )
            ),
            catchError((err) => {
              console.error(`[epic ${UPSERT_DOCUMENTO}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Operazione non riuscita',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ documentoLoader: false, page: false }))
      );
    })
  );

export default upsertDocumentoEpic;
